"use client";
import { useState, useEffect } from "react";

const Footer = () => {
  const [year, setYear] = useState("");

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setYear(currentYear.toString());
  }, []);

  return (
    <footer className="flex text-sm md:text-xl flex-col md:flex-row justify-center items-center md:justify-between m-2 md:m-5 p-1 md:p-4 rounded-lg">
      <div>
        <p>Copyright © C&C Scapes {year}. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
